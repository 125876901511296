<template>
  <div class="container">
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <canvas ref="canvas"></canvas>
    <div v-if="showHint" class="hint">Touch and drag to create cosmic smoke</div>
    <div class="controls">
      <button @click="toggleTimeAttack">
        {{ timeAttackActive ? 'End Time Attack Mode' : 'Start Time Attack Mode' }}
      </button>
      <button @click="toggleMemoryChallenge">
        {{ memoryChallengeActive ? 'End Memory Challenge Mode' : 'Start Memory Challenge Mode' }}
      </button>
      <button v-if="memoryChallengeActive && memoryChallengeState === 'challenge'" @click="submitMemoryChallenge">
        Submit Memory Challenge
      </button>
      <div v-if="timeAttackActive" class="time-attack-info">
        <div>Time Left: {{ timeAttackTimeLeft }}s</div>
        <div>Score: {{ timeAttackScore }}</div>
      </div>
      <div v-if="memoryChallengeActive" class="memory-challenge-info">
        <div v-if="memoryChallengeState === 'recording'">Recording Pattern... (draw your pattern now)</div>
        <div v-else-if="memoryChallengeState === 'challenge'">Challenge Mode: Recreate the pattern</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmokePage',
  data() {
    return {
      canvas: null,
      ctx: null,
      hue: 0,
      particles: [],
      touchActive: false,
      showHint: true,
      animationFrame: null,
      audioContext: null,
      lastSoundTime: 0,
      // Existing Gravity Mechanics properties
      gravityX: 0,
      gravityY: 0,
      // New: Time Attack Mode properties
      timeAttackActive: false,
      timeAttackDuration: 30, // seconds
      timeAttackStartTime: null,
      timeAttackScore: 0,
      timeAttackInterval: null,
      // New: Memory Challenge Mode properties
      memoryChallengeActive: false,
      memoryChallengeState: 'idle', // 'idle', 'recording', 'challenge'
      memoryChallengePattern: [],
      userMemoryPattern: [],
      memoryChallengeTimer: null
    }
  },
  computed: {
    timeAttackTimeLeft() {
      if (!this.timeAttackActive || !this.timeAttackStartTime) return this.timeAttackDuration;
      let elapsed = (Date.now() - this.timeAttackStartTime) / 1000;
      let left = Math.max(0, this.timeAttackDuration - elapsed);
      return left.toFixed(1);
    }
  },
  methods: {
    resize() {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    },
    animate() {
      this.ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      
      this.particles.forEach((particle, index) => {
        // Apply Gravity Mechanics: adjust particle speed based on device tilt
        particle.speedX += this.gravityX;
        particle.speedY += this.gravityY;

        this.drawParticle(particle);
        if (particle.alpha <= 0) this.particles.splice(index, 1);
      });

      this.hue = (this.hue + 1) % 360;
      this.animationFrame = requestAnimationFrame(this.animate);
    },
    // Modified to accept a pressure parameter for Touch Pressure Mechanics
    createParticle(x, y, pressure = 1) {
      let size = Math.random() * 15 + 5 * pressure;
      let speedX = (Math.random() * 3 - 1.5) * pressure;
      let speedY = (Math.random() * 3 - 1.5) * pressure;
      let color = `hsla(${this.hue}, 100%, 50%, 0.8)`;
      let rare = false;
      
      // Surprise Rarity Drops: occasional rare visuals appear
      let rarityChance = pressure > 1.5 ? 0.05 : 0.02;
      if (Math.random() < rarityChance) {
        rare = true;
        color = `hsla(50, 100%, 60%, 1)`; // A gold-like hue for rare particles
        size *= 1.5;  // Rare particles are larger
      }
      
      return {
        x,
        y,
        size,
        speedX,
        speedY,
        color,
        alpha: 1,
        rare
      };
    },
    drawParticle(particle) {
      this.ctx.fillStyle = particle.color;
      this.ctx.beginPath();
      this.ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      this.ctx.fill();
      
      particle.x += particle.speedX;
      particle.y += particle.speedY;
      particle.alpha -= 0.01;
      particle.size *= 0.97;
    },
    createBubbleSound(frequency) {
      if (!this.audioContext) return;
      const now = this.audioContext.currentTime;
      // Prevent overlapping sounds
      if (now - this.lastSoundTime < 0.09) return;
  
      // ----- Tonal component (oscillator) -----
      const oscillator = this.audioContext.createOscillator();
      oscillator.type = 'triangle';
      oscillator.frequency.setValueAtTime(frequency, now);
      // Create a quick pitch drop for that “pop” effect
      oscillator.frequency.exponentialRampToValueAtTime(frequency * 0.5, now + 0.05);
      // Add a slight random detune for organic variation
      oscillator.detune.setValueAtTime((Math.random() - 0.5) * 20, now);
  
      // ----- Noise component for texture -----
      // Create a short white noise burst (lasting 0.05 seconds)
      const bufferSize = this.audioContext.sampleRate * 0.05;
      const noiseBuffer = this.audioContext.createBuffer(1, bufferSize, this.audioContext.sampleRate);
      const noiseData = noiseBuffer.getChannelData(0);
      for (let i = 0; i < bufferSize; i++) {
        noiseData[i] = Math.random() * 2 - 1;
      }
      const noiseSource = this.audioContext.createBufferSource();
      noiseSource.buffer = noiseBuffer;
      noiseSource.loop = false;
  
      // ----- Common processing chain -----
      // A highpass filter emphasizes the bright, transient frequencies
      const filter = this.audioContext.createBiquadFilter();
      filter.type = 'highpass';
      filter.frequency.setValueAtTime(frequency / 2, now);
      filter.Q.setValueAtTime(1, now);
  
      // A gain node shapes the amplitude envelope
      const gainNode = this.audioContext.createGain();
      // Start at full amplitude then drop quickly to nearly zero
      gainNode.gain.setValueAtTime(1, now);
      gainNode.gain.exponentialRampToValueAtTime(0.001, now + 0.05);
  
      // ----- Merge the oscillator and noise components -----
      const merger = this.audioContext.createGain();
      oscillator.connect(merger);
      noiseSource.connect(merger);
  
      // Connect processing chain: merged sources -> filter -> gain -> destination
      merger.connect(filter);
      filter.connect(gainNode);
      gainNode.connect(this.audioContext.destination);
  
      // Start both sources and stop shortly after
      oscillator.start(now);
      noiseSource.start(now);
      oscillator.stop(now + 0.05);
      noiseSource.stop(now + 0.05);
  
      // Update last sound time for throttling
      this.lastSoundTime = now;
    },
    // New: Device orientation handler for Gravity Mechanics
    handleDeviceOrientation(e) {
      // e.gamma: left-to-right tilt (in degrees, range [-90,90])
      // e.beta: front-to-back tilt (in degrees, range [-180,180])
      // Map these values to a small acceleration factor.
      const factor = 0.05; // Adjust for desired sensitivity
      this.gravityX = (e.gamma || 0) / 90 * factor;
      this.gravityY = (e.beta || 0) / 90 * factor;
    },
    handleTouchStart(e) {
      this.touchActive = true;
      
      // Memory Challenge Mode: record touch coordinates if active
      if (this.memoryChallengeActive) {
        if (this.memoryChallengeState === 'recording') {
          this.memoryChallengePattern.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        } else if (this.memoryChallengeState === 'challenge') {
          this.userMemoryPattern.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        }
      }
      
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }
      if (this.audioContext.state === 'suspended') {
        this.audioContext.resume();
      }
      
      // Gentle vibration pattern [duration, pause, duration]
      if(navigator.vibrate) navigator.vibrate([10, 2, 5]);
      
      // Touch Pressure Mechanics: use touch force if available
      const pressure = e.touches[0].force || 1;
      this.addParticles(e.touches[0].clientX, e.touches[0].clientY, pressure);
      
      // Base bubble sound with random harmonics
      const baseFreq = 200 + Math.random() * 800;
      this.createBubbleSound(baseFreq);
      this.createBubbleSound(baseFreq * 2);
    },
    handleTouchMove(e) {
      if(this.touchActive) {
        // Memory Challenge Mode: record touch coordinates if active
        if (this.memoryChallengeActive) {
          if (this.memoryChallengeState === 'recording') {
            this.memoryChallengePattern.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
          } else if (this.memoryChallengeState === 'challenge') {
            this.userMemoryPattern.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
          }
        }
        
        const intensity = Math.random();
        const pressure = e.touches[0].force || 1;
        this.addParticles(e.touches[0].clientX, e.touches[0].clientY, pressure);
        
        // Variable vibrations based on movement speed
        if(navigator.vibrate) {
          const vibIntensity = Math.floor(3 + intensity * 5);
          navigator.vibrate(vibIntensity);
        }
        
        // Add bubbly sounds with random variations
        if(Math.random() < 0.3) {
          const freq = 300 + Math.random() * 1500;
          this.createBubbleSound(freq);
        }
      }
    },
    handleTouchEnd() {
      this.touchActive = false;
      // Final bubble pop sound
      this.createBubbleSound(1500);
      this.createBubbleSound(800);
      if(navigator.vibrate) navigator.vibrate([5, 10, 5]);
    },
    // Modified: Include pressure parameter to control particle count
    addParticles(x, y, pressure = 1) {
      const particleCount = Math.floor(5 * pressure);
      for(let i = 0; i < particleCount; i++) {
        this.particles.push(this.createParticle(x, y, pressure));
      }
      // Time Attack Mode: increment score based on particles created
      if (this.timeAttackActive) {
        this.timeAttackScore += particleCount;
      }
    },
    // New: Time Attack Mode methods
    toggleTimeAttack() {
      if (!this.timeAttackActive) {
        this.startTimeAttack();
      } else {
        this.endTimeAttack();
      }
    },
    startTimeAttack() {
      this.timeAttackActive = true;
      this.timeAttackStartTime = Date.now();
      this.timeAttackScore = 0;
      this.timeAttackInterval = setInterval(() => {
        if (this.timeAttackTimeLeft <= 0) {
          this.endTimeAttack();
        }
      }, 100);
    },
    endTimeAttack() {
      clearInterval(this.timeAttackInterval);
      this.timeAttackInterval = null;
      this.timeAttackActive = false;
      alert(`Time Attack Over! Your score: ${this.timeAttackScore}`);
    },
    // New: Memory Challenge Mode methods
    toggleMemoryChallenge() {
      if (!this.memoryChallengeActive) {
        this.startMemoryChallenge();
      } else {
        this.endMemoryChallenge();
      }
    },
    startMemoryChallenge() {
      this.memoryChallengeActive = true;
      this.memoryChallengeState = 'recording';
      this.memoryChallengePattern = [];
      this.userMemoryPattern = [];
      alert("Memory Challenge Mode: Draw your pattern for 5 seconds!");
      this.memoryChallengeTimer = setTimeout(() => {
        this.memoryChallengeState = 'challenge';
        // Optionally clear the canvas to hide the recorded pattern
        this.particles = [];
        alert("Now, recreate the pattern!");
      }, 5000);
    },
    endMemoryChallenge() {
      this.memoryChallengeActive = false;
      this.memoryChallengeState = 'idle';
      if (this.memoryChallengeTimer) {
        clearTimeout(this.memoryChallengeTimer);
        this.memoryChallengeTimer = null;
      }
    },
    submitMemoryChallenge() {
      if (!this.memoryChallengeActive || this.memoryChallengeState !== 'challenge') return;
      let correct = 0;
      const len = Math.min(this.memoryChallengePattern.length, this.userMemoryPattern.length);
      for (let i = 0; i < len; i++) {
        const dx = this.memoryChallengePattern[i].x - this.userMemoryPattern[i].x;
        const dy = this.memoryChallengePattern[i].y - this.userMemoryPattern[i].y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < 50) {  // threshold in pixels
          correct++;
        }
      }
      const accuracy = len ? (correct / len) * 100 : 0;
      alert(`Memory Challenge Accuracy: ${accuracy.toFixed(1)}%`);
      this.endMemoryChallenge();
    }
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext('2d');
    this.resize();
    window.addEventListener('resize', this.resize);
    // New: Listen for device orientation for Gravity Mechanics
    window.addEventListener('deviceorientation', this.handleDeviceOrientation);
    this.animate();

    // Add touch event listeners
    this.canvas.addEventListener('touchstart', this.handleTouchStart);
    this.canvas.addEventListener('touchmove', this.handleTouchMove);
    this.canvas.addEventListener('touchend', this.handleTouchEnd);

    // Hide hint after 5 seconds
    setTimeout(() => this.showHint = false, 5000);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('deviceorientation', this.handleDeviceOrientation);
    cancelAnimationFrame(this.animationFrame);
    this.canvas.removeEventListener('touchstart', this.handleTouchStart);
    this.canvas.removeEventListener('touchmove', this.handleTouchMove);
    this.canvas.removeEventListener('touchend', this.handleTouchEnd);
    if (this.audioContext) {
      this.audioContext.close();
    }
  }
}
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  touch-action: none;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

.hint {
  position: fixed;
  color: rgba(255,255,255,0.3);
  font-family: Arial, sans-serif;
  padding: 20px;
  user-select: none;
  z-index: 1000;
  pointer-events: none;
}

.controls {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.controls button {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.time-attack-info,
.memory-challenge-info {
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
</style>
