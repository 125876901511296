import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/Home.vue';
import AboutPage from '@/views/About.vue';
import ContactPage from '@/views/Contact.vue';
import TestimonialsPage from '@/views/Testimonials.vue';
import BlogPage from '@/views/Blog.vue';
import SmokePage from '@/views/Smoke.vue';


const routes = [
  { path: '/', component: HomePage },
  { path: '/about', component: AboutPage},
  { path: '/contact', component: ContactPage },
  { path: '/testimonials', component: TestimonialsPage },
  { path: '/blog', component: BlogPage },
  { path: '/smk', component: SmokePage },


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
