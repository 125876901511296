<template>
  <div class="section">
    <div class="container">
      <br />
      <h1 class="title has-text-centered has-text-white">Latest Blogs</h1>
      <p class="content has-text-centered has-text-grey-light">Stay updated with our latest posts.</p>

      <div v-if="loading" class="has-text-centered">
        <i class="fas fa-spinner fa-spin fa-3x has-text-primary"></i>
        <p class="has-text-white">Loading blogs...</p>
      </div>

      <div v-else-if="blogs.length > 0" class="columns is-multiline">
        <div class="column is-4" v-for="blog in blogs" :key="blog.id">
          <div class="box blog-card">
  <h2 class="title is-5">{{ blog.title }}</h2>
  <p class="subtitle is-6 has-text-grey-light">By {{ blog.author }} | {{ formatDate(blog.created_at) }}</p>
  <p class="content">{{ truncateText(blog.content, 150) }}</p>
  <button class="button is-link is-light" @click="readMore(blog)">
    Read More <i class="fas fa-arrow-right ml-2"></i>
  </button>
</div>

        </div>
      </div>

      <div v-else class="has-text-centered">
        <p class="is-size-5 has-text-danger">No blogs available.</p>
      </div>

      <div v-if="selectedBlog" class="modal is-active">
        <div class="modal-background" @click="selectedBlog = null"></div>
        <div class="modal-content dark-box">
          <h2 class="title has-text-white">{{ selectedBlog.title }}</h2>
          <p class="subtitle has-text-grey-light">By {{ selectedBlog.author }} | {{ formatDate(selectedBlog.created_at) }}</p>
          <div class="content has-text-white" v-html="formatMarkdown(selectedBlog.content)"></div>
          <button class="button is-danger is-light mt-3" @click="selectedBlog = null">
            Close <i class="fas fa-times ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BlogPage",
  data() {
    return {
      blogs: [],
      loading: true,
      selectedBlog: null,
    };
  },
  methods: {
    async fetchBlogs() {
      try {
        const response = await axios.get("https://tutorhirebackend.onrender.com/api/blogs/");
        this.blogs = response.data;
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString("en-US", options);
    },
    truncateText(text, length) {
      // Clean markdown symbols for preview
      const cleanText = text.replace(/[#*]+/g, '');
      return cleanText.length > length ? cleanText.substring(0, length) + "..." : cleanText;
    },
    formatMarkdown(text) {
      // Convert markdown-like syntax to HTML
      return text
        .replace(/^# (.+)$/gm, '<h1>$1</h1>')              // # Heading
        .replace(/^## (.+)$/gm, '<h2>$1</h2>')             // ## Heading
        .replace(/^### (.+)$/gm, '<h3>$1</h3>')            // ### Heading
        .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')   // **bold**
        .replace(/\*(.+?)\*/g, '<em>$1</em>')              // *italic*
        .replace(/\n/g, '<br>');                           // New lines
    },
    readMore(blog) {
      this.selectedBlog = blog;
    },
  },
  mounted() {
    this.fetchBlogs();
  },
};
</script>

<style scoped>
.section {
  min-height: 100vh;
}

.blog-card {
  animation: fadeIn 0.6s ease-in-out;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: #1a1a1a;
  color: #ffffff;
  padding: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}

.blog-card .subtitle {
  color: #b0b0b0;
}

.button.is-link.is-light {
  background: #007bff;
  color: #ffffff;
  border: none;
}

.button.is-link.is-light:hover {
  background: #0056b3;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dark-box {
  max-width: 600px;
  background: #2d2d2d;
  padding: 20px;
  border-radius: 10px;
  animation: fadeIn 0.3s ease-in-out;
}

/* Styling for markdown content */
.content {
  line-height: 1.6;
}

.content h1 {
  font-size: 1.8rem;
  margin: 1rem 0;
  color: #ffffff;
}

.content h2 {
  font-size: 1.5rem;
  margin: 0.8rem 0;
  color: #e0e0e0;
}

.content h3 {
  font-size: 1.2rem;
  margin: 0.6rem 0;
  color: #d0d0d0;
}

.content strong {
  font-weight: bold;
  color: #ffffff;
}

.content em {
  font-style: italic;
  color: #f0f0f0;
}
</style>
