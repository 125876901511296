<template>
  <div id="app">
    <Navbar />
    <main class="content">
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style>
@import "~bulma/css/bulma.css";
body {
  background-color: #1a202c;
  color: #fff;
}
.content {
  flex: 1; /* Allows it to take the available space */
  overflow-y: auto; /* Enables scrolling if needed */
}
.app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}
</style>
