<template>
  <footer class="footer has-background-dark has-text-light">
    <div class="container">
      <div class="columns is-mobile is-multiline">
        <!-- Branding and Slogan -->
        <div class="column is-12-mobile is-4-tablet">
          <h2 class="title is-4 has-text-light">Tutor Hire</h2>
          <p>Your gateway to the best tutors at your service.</p>
          <img src="@/assets/logo.png" alt="Tutor Hire Logo" class="footer-logo mt-3">
        </div>
        
        <!-- Quick Links -->
        <div class="column is-6-mobile is-2-tablet">
          <h2 class="title is-5 has-text-light">Quick Links</h2>
          <ul>
            <li><a href="#" class="has-text-light">Home</a></li>
            <li><a href="#" class="has-text-light">About Us</a></li>
            <li><a href="#" class="has-text-light">Contact Us</a></li>
            <li><a href="#" class="has-text-light">Testimonials</a></li>
            <li><a href="#" class="has-text-light">FAQs</a></li>
          </ul>
        </div>

        <!-- Social Media -->
        <div class="column is-6-mobile is-3-tablet">
          <h2 class="title is-5 has-text-light">Follow Us</h2>
          <div class="social-icons">
            <a href="#" class="icon is-medium has-text-light">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="#" class="icon is-medium has-text-light">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="icon is-medium has-text-light">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="#" class="icon is-medium has-text-light">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
          <p class="mt-4">Get 50% off your first order!</p>
          <a href="#" class="button is-primary mt-2 is-fullwidth-mobile">Claim Offer</a>
        </div>

        <!-- Newsletter Subscription -->
        <div class="column is-12-mobile is-3-tablet">
          <h2 class="title is-5 has-text-light">Subscribe to Our Newsletter</h2>
          <div class="field">
            <p class="control has-icons-left">
              <input class="input is-rounded" type="email" placeholder="Enter your email">
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <button class="button is-primary is-rounded is-fullwidth-mobile">Subscribe</button>
        </div>
      </div>

      <hr class="has-background-light">

      <div class="content has-text-centered">
        <p>
          <strong>KingWriters</strong> by <a href="#" class="has-text-primary">iChrispin</a>. 
          <br>
          © 2024 KingWriters. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterPage',
};
</script>

<style scoped>
.footer-logo {
  max-width: 150px;
}

.social-icons a {
  margin-right: 10px;
}

.button.is-primary {
  background-color: #3498db;
  border-color: #3498db;
}

.button.is-primary:hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

.hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.footer {
  flex-shrink: 0; /* Prevents shrinking */
  }

@media (max-width: 768px) {
  .footer-logo {
    max-width: 120px;
  }

  .social-icons a {
    margin-right: 5px;
  }

  .title {
    font-size: 1.25rem;
  }
}
</style>
